
/**
 * @file index
 */
import {Component} from 'san';
import {router} from 'san-router';
import {routerMap} from '@/router/routes';
import {
    checkIsRedirect,
    redirect,
} from '@/router/redirect';
import store from '@/store/store';
import {getLoginInfo} from '@/api';
import {getPageInfo} from '@/api/page';
import {removeSkeleton} from '@/utils/util';
import {setPageParams} from '@/utils/set-page-params';

import '@/assets/css/app.css';
import '@/assets/css/iconfont.css';
import '@/assets/less/finance.less';
import '@/assets/css/finance.css';

import loginInstance from '@/utils/login';
// import '@/utils/log';
import {sendError, sendDist} from '@/utils/monitor';

// import App from '@/lib/App';
import Layout from './containers/layout.san';
import Simple from './containers/simple.san';

let pageLayout = true;

router.setMode('html5');
routerMap.forEach(item => {
    router.add({
        target: '#main',
        ...item,
    });
});

router.listen((e, config) => {

    // config 不存在，说明该路由不存在，直接定向到首页
    if (!config) {
        e.stop();
        router.locator.redirect('/');
        return;
    }

    // 非百度来源渠道
    if (document.referrer && !document.referrer.includes('baidu.com')) {
        e.stop();

        // 若为港股个股详情页，则重定向到首页
        if (window.location.pathname.includes('stock/hk')) {
            router.locator.redirect(`/?notbaiduorigin=1&topath=${window.location.href}`);
        }
        else {

            // 更新为百度来源
            window.location.href = window.location.href;
        }
        return;
    }

    if (checkIsRedirect(config.page)) {
        e.stop();
        redirect(config.page, e.query);
        return;
    };

    // 前端路由切换时，请求页面page信息
    console.log('config', e, router.locator);
    if (router.locator.referrer && router.locator.current !== router.locator.referrer) {
        getPageInfo(router.locator.current).then(({
            pageDescription,
            pageKeywords,
            pageTitle,
        }) => {
            pageTitle && (document.title = pageTitle);
            pageDescription && (document.querySelector('meta[name="description"]').content = pageDescription);
            pageKeywords && (document.querySelector('meta[name="keywords"]').content = pageKeywords);
        }).catch(_ => {
            // do nothing
        });
    }


    setPageParams(config.page, e.query);

    //  百度统计，切换路由的时候防止少pv
    if (window._hmt) {
        let params = '';
        if (e.query.market) {
            params += `-${e.query.market}`;
        }
        if (e.query.type) {
            params += `-${e.query.type}`;
        }
        window._hmt.push(['_trackPageview', `${e.path + params}`]);
    }

    if (config) {
        store.dispatch('setCurPage', config.page);
    }

    // 非首页和详情页移除骨架屏
    if (config && (config.page !== 'home' && config.page !== 'detail')) {
        removeSkeleton();
    }

    // 切换路由时移除footer，页面attached时再显示
    store.dispatch('setShowFooter', false);
    scrollTo(0, 0);

    if (e.path.indexOf('/downloadpage/xiaogu') !== -1
        || e.path === '/downloadpage/gushitong'
        || e.path === '/downloadpage/article') {
        pageLayout = false;
    }
});

router.start();

Component.prototype.$sendError = sendError;
Component.prototype.$router = router;
Component.prototype.$store = store;
Component.prototype.$login = () => {
    loginInstance.show();
};

const app = pageLayout ? new Layout() : new Simple();
app.attach(document.getElementById('app'));

getLoginInfo().then(res => {
    const {isLogin, avatar = ''} = res || {};
    store.dispatch('setLoginInfo', {
        isLogin: +isLogin === 1,
        avatar,
    });
    sendDist({
        isLogin: +isLogin === 1,
    });
});

store.dispatch('getParis');
