/**
 * @file api/index.js
 * @author v_zhaoxinggang@baidu.com
 * @desc 金融 pc 站异步 api 定义
 */

import request from '@/utils/request';
import {sendError} from '@/utils/monitor';
import _ from 'lodash';


// 这里只会读取OPEN_API和FINANCE_API这两个路径，其他配的线下域名不要引入！！！
// 本地开发环境本身不管你什么域名都会给你清空，只有上线的时候才会用到，这里引用线下域名会出上线case！！！
let {OPEN_API, FINANCE_API} = require('@api/api_prefix');

// const isDev = process.env.ENV_TYPE === 'dev';

// if (isDev) {
//     OPEN_API = '/api.php';
//     FINANCE_API = '';
// }

// 异步接口首先跟后端确认路径是FINANCE_API还是OPEN_API
// 线下开发把路径改为后端机器的路径，并自行确认新增接口路径时候需要配置新代理

/**
 * 搜索 api
 *
 * @param {Object} params 搜索参数集合
 * @param {string} params.wd 搜索词
 */
export async function search(params) {
    return new Promise((resolve, reject) => {
        request({
            url: FINANCE_API + '/selfselect/sug',
            method: 'GET',
            params,
        }).then(res => {
            try {
                if (res && +res.ResultCode === 0) {
                    if (Array.isArray(res.Result)) {
                        resolve([]);
                    }
                    else if (res.Result && _.get(res, 'Result.stock')) {
                        resolve(_.get(res, 'Result.stock'));
                    }
                }
                else {
                    resolve([]);
                }
            }
            catch (err) {
                resolve([]);
            }
        }).catch(() => reject());
    });
};

/**
 * 搜索历史
 */
export async function getSearchHis() {
    return new Promise((resolve, reject) => {
        request({
            url: FINANCE_API + '/vapi/v1/search/history',
            method: 'GET',
            params: {
                pn: 0,
                rn: 10,
            },
        }).then(res => {
            try {
                if (res && +res.ResultCode === 0) {
                    resolve(res.Result);
                }
                else {
                    resolve({list: []});
                }
            }
            catch (err) {
                resolve({list: []});
            }
        }).catch(() => {
            reject();
            sendError({
                msg: 'home-search-history',
            });
        });
    });
}

/**
 * 获取指数列表
 *
 * @param {string} market 股票市场
 */
export async function getIndexList(market = '') {
    return new Promise((resolve, reject) => {
        request({
            url: FINANCE_API + '/api/indexbanner',
            method: 'GET',
            params: {
                market,
            },
        }).then(res => {
            if (res && +res.ResultCode === 0) {
                resolve(res.Result);
            }
            else {
                reject();
                sendError({
                    msg: 'home-indexs-api-error',
                });
            }
        });
    });
}

/**
 * 获取全球指数banner列表，2023-02-09
 *
 */
export async function getGlobalBanners(params) {
    return request({
        url: FINANCE_API + '/api/getbanner',
        method: 'GET',
        params,
    });
};

/**
 * 获取外汇banner数据列表
 *
 * @param {string} market 股票市场
 */
export async function getForeignBannerList(data) {
    return new Promise((resolve, reject) => {
        request({
            url: FINANCE_API + '/api/getforeignbanner',
            method: 'GET',
            params: {
                market: 'global',
                type: 'foreign',
                rn: data?.rn || 3,
            },
        }).then(res => {
            if (res && +res.ResultCode === 0) {
                resolve(res.Result);
            }
            else {
                reject();
                sendError({
                    msg: 'home-indexs-api-error',
                });
            }
        });
    });
}

/**
 * 获取首页首屏自选和自选推荐股票列表
 */
export async function getSelfStockList({market = '', type = '', skipLogin = 1, rn = 6} = {}) {
    return new Promise((resolve, reject) => {
        request({
            url: FINANCE_API + '/selfselect/gethomeinfo',
            method: 'GET',
            params: {
                delay_num: 20,
                delay_time: 15,
                market,
                type,
                pn: 0,
                rn,
                skip_login: skipLogin,
            },
        }).then(res => {
            if (res && +res.ResultCode === 0) {
                resolve(res.Result);
            }
            else {
                reject();
                sendError({
                    msg: 'home-self-stock-list-api-error',
                });
            }
        });
    });
}

/**
 * 获取首页自选推荐股票列表，用于点击换一批按钮时
 *
 * @param {number} pn 页码
 * @param {number} skipLogin 是否跳过登录验证 1 跳过 0 跳过
 */
export async function getSelfRecommentedStockList(pn, market, type, skipLogin = 1) {
    return new Promise((resolve, reject) => {
        request({
            url: FINANCE_API + '/selfselect/recstock',
            method: 'GET',
            params: {
                pn,
                rn: 10,
                market,
                type,
                skip_login: skipLogin,
            },
        }).then(res => {
            if (res && +res.ResultCode === 0) {
                resolve(res.Result);
            }
            else {
                reject();
                sendError({
                    msg: 'home-self-recommented-stock-list-api-error',
                });
            }
        });
    });
}

export async function addStock(stocks) {
    return new Promise((resolve, reject) => {
        request({
            url: FINANCE_API + '/selfselect/addstock',
            method: 'POST',
            params: {
                stock: JSON.stringify(stocks),
            },
            config: {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            },
        }).then(res => {
            if (res && +res.ResultCode === 0) {
                resolve();
            }
            else {
                reject();
                sendError({
                    msg: 'add-stock-api-error',
                });
            }
        });
    });
}

export async function delStock(stocks) {
    return new Promise((resolve, reject) => {
        request({
            url: FINANCE_API + '/selfselect/delstock',
            method: 'POST',
            params: {
                stock: JSON.stringify(stocks),
            },
            config: {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            },
        }).then(res => {
            if (res && +res.ResultCode === 0) {
                resolve();
            }
            else {
                reject();
                sendError({
                    msg: 'del-stock-api-error',
                });
            }
        });
    });
}

/**
 * 获取用户信息
 */
export async function getLoginInfo() {
    return new Promise((resolve, reject) => {
        request({
            url: FINANCE_API + '/vapi/v1/userinfo',
            method: 'GET',
        }).then(res => {
            if (res && +res.ResultCode === 0) {
                resolve(res.Result);
            }
            else {
                reject();
                sendError({
                    msg: 'get-user-info-error',
                });
            }
        });
    });
}

/**
 * 获取资讯整体数据
 */
export async function getFlashData({rn, pn, type, tag}) {
    return request({
        url: FINANCE_API + '/selfselect/news',
        method: 'GET',
        params: {
            rn,
            pn,
            type,
            tag,
        },
    });
}

/**
 * 获得7*24资讯数据
 */
export function getExpressNews({rn, pn, tag, financeType, code}) {
    return request({
        url: FINANCE_API + '/selfselect/expressnews',
        method: 'GET',
        params: {
            rn,
            pn,
            tag,
            financeType,
            code,
        },
    });
}

/**
 * 全球指数列表
 */
export function getGlobalData(market) {
    const url = FINANCE_API + '/vapi/index/overview';
    return request({
        url,
        method: 'GET',
        params: {
            market,
        },
    });
}

/**
 * 全球指数详情页
 */
export function getGlobalList(params) {
    return request({
        url: FINANCE_API + '/vapi/v1/globalindexrank',
        method: 'GET',
        params,
    });
}


/**
 * 获得首页板块数据
 */
export function getBlocksData(market) {
    return request({
        url: FINANCE_API + '/vapi/v1/blocks/overview',
        method: 'GET',
        params: {
            hasTrend: 1,
            market,
        },
    });
}

/**
 * 获得首页期货数据
 */
export function getQihuo(params) {
    return request({
        url: FINANCE_API + '/vapi/v1/futuresoverview',
        method: 'GET',
        params,
    });
}

/**
 * 获得期货二级页数据
 */
export function getQihuoList(params) {
    return request({
        url: FINANCE_API + '/vapi/v1/getfutureslist',
        method: 'GET',
        params,
    });
}


/**
 * 获得板块列表
 */
export function getBlocksListData(params) {
    return request({
        url: FINANCE_API + '/vapi/v2/blocks',
        method: 'GET',
        params,
    });
}

/*
 * 退出登录，直接调用passport接口, 绕过跨域
 */
export async function loginOut() {
    // 退出登录需要返回首页的页面
    const backHome = ['/self'];
    const img = new Image();
    const urlParams = new URL(location.href);
    const home = urlParams.origin;
    const path = urlParams.pathname;
    img.onload = function () {
        if (backHome.includes(path)) {
            location.href = home;
            return;
        }
        location.reload();
    };
    img.onerror = function () {
        if (backHome.includes(path)) {
            location.href = home;
            return;
        }
        location.reload();
    };
    img.src = 'https://passport.baidu.com/?logout';
}

/**
 * 获得榜单数据
 */
export function getListData(params) {
    return request({
        url: FINANCE_API + '/selfselect/getmarketrank',
        method: 'GET',
        params,
    });
}

/**
 * 获取行页-港股通tab下数据
 * @param {*} params 参数
 * @returns 接口返回信息
 */
export function getHkStockInfo({module, param}) {
    return request({
        url: FINANCE_API + '/vapi/v1/gettradeinfo',
        method: 'GET',
        params: {
            module,
            param,
        },
    });
}

/**
 * 获得基金榜单数据（基金行情）
 */
export function getFundListData(params) {
    return request({
        url: FINANCE_API + '/vapi/v1/getfundrank',
        method: 'GET',
        params,
    });
}

/**
 * 获得外汇榜单数据（外汇行情）
 */
export function getForeignListData(params) {
    return request({
        url: FINANCE_API + '/api/getforeignrank',
        method: 'GET',
        params,
    });
}

/**
 * 获得自选列表数据
 */
export function getSelfselectData(params) {
    return request({
        url: FINANCE_API + '/selfselect/gethomeinfo',
        method: 'GET',
        params,
    });
}

/**
 * 自选列表排序
 */
export function sortSelfselectData(stocks, market, type) {
    return request({
        url: FINANCE_API + '/selfselect/sortstock',
        method: 'POST',
        header: {
            'content-type': 'application/x-www-form-urlencoded',
        },
        params: {
            stock: JSON.stringify(stocks),
            market,
            type,
        },
    });
}

/**
 * 获得热股数据
 */
export function getHotStock(params) {
    return request({
        url: FINANCE_API + '/vapi/v1/hotrank?tn=wisexmlnew&dsp=iphone&product=stock',
        method: 'GET',
        params,
    });
}

/**
 * 获得热搜数据
 */
export function getHotSreach(params) {
    return request({
        url: FINANCE_API + '/selfselect/listsugrecomm?tn=wisexmlnew&dsp=iphone&product=search',
        method: 'GET',
        params,
    });
}

/**
 * 获取个股新闻
 */
export function getIndividualStockNews(url, {pn, rn}) {
    return request({
        // RD返回接口地址有误，前端fix，替换接口
        url: url.replace('https://sp0.baidu.com/8aQDcjqpAAV3otqbppnN2DJv/api.php', '/opendata'),
        method: 'GET',
        params: {
            pn,
            rn,
        },
    });
}

/**
 * 获取异动记录
 */
export function getMoveRecordList({market, type, anomalyType, pn = 0, rn = 20}) {
    return request({
        url: OPEN_API + '?resource_id=50742&anomaly_date=&query=anomaly_signal',
        method: 'GET',
        params: {
            market_type: market,
            anomaly_qry_type: anomalyType,
            anomaly_type: type,
            pn,
            rn,
        },
    });
}

/**
 * 获取主力净流入数据
 */
export function tradeOwnerIncome({market, typeCode, pn = 0, rn = 20}) {
    return request({
        url: FINANCE_API + '/vapi/v3/blocks?sort_key=exmainIn&sort_type=down',
        method: 'GET',
        params: {
            market,
            type_code: typeCode,
            pn,
            rn,
        },
    });
}

/**
 * 获取舆情tab数据
 */
export function getPublicTabList(params) {
    return request({
        url: FINANCE_API + '/vapi/sentimentrank',
        method: 'GET',
        params: {
            ...params,
        },
    });
}

export function getStockPublicList(params) {
    return request({
        url: FINANCE_API + '/vapi/sentimentlist',
        method: 'GET',
        params,
    });
}
/**
 * 获取板块数据
 */

export function getBlock(params) {
    return request({
        url: FINANCE_API + '/selfselect/listsugrecomm?tn=wisexmlnew&dsp=iphone&product=plate',
        method: 'GET',
        params,
    });
}

/**
 * 获取财经日历的数据
 */
export function getFinanceCalendar(params) {
    return new Promise((reslove, reject) => {
        request({
            url: FINANCE_API + '/api/financecalendar',
            method: 'GET',
            params,
        }).then(res => {
            if (res && +res.ResultCode === 0 && res.Result) {
                reslove(res.Result);
            }
            else {
                reslove([]);
            }
        }).catch(() => reject(99999));
    });
}

/**
 * 后端点击日志
 */
export function clickLogAjax(params) {
    return new Promise(() => {
        request({
            url: FINANCE_API + '/vapi/v1/record',
            method: 'GET',
            params,
        });
    });
}

export function getBlockList(stock) {
    return new Promise((resolve, reject) => {
        request({
            url: FINANCE_API + '/api/getrelatedblock',
            method: 'GET',
            params: {
                stock,
            },
        }).then(res => {
            if (res && res.ResultCode === '0' && res.Result) {
                resolve(res.Result);
            }
            else {
                resolve([]);
            }
        }).catch(() => reject(9999));
    });
}

/**
 * rightpaddle支付中台请求支付
 */
export function getPayUrl(user_id) {
    return new Promise((resolve, reject) => {
        request({
            url: ' https://sp0.baidu.com/5LMDcjW6BwF3otqbppnN2DJv/finance.pae.baidu.com/vapi/rightpaddle/pay',
            method: 'GET',
            params: {
                user_id,
            },
        }).then(res => {
            if (res && res.errno === 0 && res.data) {
                resolve(res.data);
            }
            else {
                resolve([]);
            }
        }).catch(() => reject(9999));
    });
}

/**
 * rightpaddle验证会员信息
 */
export function isMember(user_id) {
    return new Promise((resolve, reject) => {
        request({
            url: 'https://sp0.baidu.com/5LMDcjW6BwF3otqbppnN2DJv/finance.pae.baidu.com/vapi/rightpaddle/ismember',
            method: 'GET',
            params: {
                user_id,
            },
        }).then(res => {
            if (res && res.errno === 0 && res.data !== null) {
                resolve(res.data);
            }
            else {
                resolve([]);
            }
        }).catch(() => reject(9999));
    });
}

export function getMainAnalysis(params) {
    return new Promise((resolve, reject) => {
        request({
            url: FINANCE_API + '/vapi/v1/analysis',
            method: 'GET',
            params,
        }).then(res => {
            if (res && +res.ResultCode === 0 && res.Result) {
                resolve(res.Result);
            }
            else {
                reject(9999);
            }
        }).catch(() => reject(9999));
    });
}

/**
 * 获取压力位支撑位数据
 */
export function getPressureSupportData(params) {
    return new Promise((resolve, reject) => {
        request({
            url: FINANCE_API + '/vapi/v1/getanalysisquotation',
            method: 'GET',
            params,
        }).then(res => {
            if (res && +res.ResultCode === 0 && res.Result) {
                resolve(res.Result);
            }
            else {
                reject(9999);
            }
        }).catch(() => reject(9999));
    });
}

/**
 * 获取舆情数据
 *
 * @param {Object} params 请求参数
 * @param {string} params.name 名称
 * @param {string} params.code code
 * @param {string} params.type 类型
 * @param {string} params.market 市场
 * @param {string} params.query 名称
 */
export const getPublicOpinionData = params => {
    return request({
        url: FINANCE_API + '/vapi/async',
        method: 'GET',
        params: {
            srcid: '52609',
            from: 'rating',
            ...params,
        },
    });
};


/**
 * 获取指定市场行业列表
 *
 * @param {Object} params.market 市场
 */
export const getIndustryList = (params = {}) => {
    return request({
        url: FINANCE_API + '/vapi/v1/getindustrylist',
        method: 'GET',
        params,
    });
};

/**
 * 获取诊股列表数据
 *
 * @param {Object} params.market 市场
 * @param {String} params.pn 当前数量
 * @param {String} params.rn 偏移量
 * @param {String} params.firstIndustry 一级行业，默认全部
 * @param {String} params.secondIndustry 二级行业，默认全部
 * @param {String} params.orderBy 排序字段
 * @param {String} params.order 排序规则, 升序asc,降序desc,默认降序
 */
export const getStockAnalysisList = (params = {}) => {
    return request({
        url: FINANCE_API + '/vapi/v1/analysisrank',
        method: 'GET',
        params: {
            product: 'analysis',
            ...params,
        },
    });
};

/**
 * 获取合作券商列表
 */
export const getBrokerList = () => {
    return request({
        url: 'https://sp0.baidu.com/5LMDcjW6BwF3otqbppnN2DJv/finance.pae.baidu.com/finance/stockaccountlist',
        method: 'GET',
    });
};
