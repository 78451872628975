import request from '@/utils/request';

const {FINANCE_API} = require('@api/api_prefix');

export async function getPageInfo(path) {
    return new Promise((resolve, reject) => {
        request({
            url: `${FINANCE_API}/vapi/getpcdesc`,
            params: {
                path,
            },
            method: 'GET',
        }).then(res => {
            if (res && +res.ResultCode === 0) {
                resolve(res.Result);
            }
            else {
                reject();
            }
        });
    });
}